import Repo2 from "./Repo2";
import { applySearchFilter } from "@/services/utilities/search-filter";

const resource = "api/v1/boarding";

export default {
    getBoarding(query = {}) {
        return Repo2(`${resource}/?${applySearchFilter(query)}`);
    },
    getBoardingById(id) {
        return Repo2(`${resource}/${id}/`);
    },
    createBoarding(data) {
        return Repo2(`${resource}/`, {
            method: "post",
            data,
        });
    },
    updateBoarding(id, data) {
        return Repo2(`${resource}/${id}/`, {
            method: "patch",
            data,
        });
    },
    deleteBoarding(id) {
        return Repo2.delete(`${resource}/${id}/`);
    },
    options() {
        return Repo2(`${resource}/`, {
            method: "OPTIONS",
        });
    },
    getOnBoardingTasks(query = {}) {
        return Repo2(`${resource}/?type=onboarding&${applySearchFilter(query)}`);
    },
    getOffBoardingTasks(query = {}) {
        return Repo2(`${resource}/?type=offboarding&${applySearchFilter(query)}`);
    },
    getBoardingPolicies(query = {}) {
        return Repo2(`${resource}/?${applySearchFilter(query)}`);
    },
    acceptBoardingPolicy(id, data) {
        return Repo2(`/api/v1/boarding/${id}/`, {
            method: "patch",
            data,
        });
    },
};

import isOnline from "is-online"
import Toastify from '@/helpers/toastify2'
import { toastBodyFn } from '@/helpers/toastBodyContainer'

export const showResponseMessage = (response, hideNotification = false, notificationMessage = "") => {
  if (!response) return

  // hide response notification as user need
  if (hideNotification) return
  
  // show detail message from api or default one
  let message = notificationMessage || response?.data?.detail || "Success, Data Created Successfully!"

  if ((response.config?.method === "post" && response?.status == 200) || response?.status == 201) {
    callToastifyFn(message, "success")
  }

  else if (response.config?.method === "patch" && response?.status == 200) {
    callToastifyFn("Success, Data Updated Successfully!", "success")
  }
}

export const showErrorMessage = async (error, hideNotification = false) => {
  if (!error) return

  // hide response notification as user need
  if (hideNotification) return

  let type = ""
  let message = error.message ?? "Error"

  // check internet connection
  if (!error.response) {
    const internetAvailable = await isOnline()
    if (!internetAvailable) {
      message = "Please, check your internet connection"
      type = "error"
    }
  }

  let requestData = error.config?.data && typeof error.config?.data == "string" ? JSON.parse(error.config?.data) : {}

  // check if the response data exist in request data => then it is an error validation message and show it
  if (typeof error?.response?.data !== "string" && error?.response?.data) {
    Object.keys(error?.response?.data)?.forEach((responseKey, index) => {
    //   if (responseKey in requestData) {
    message += `\n${responseKey}: ${error.response?.data[responseKey]}`
    //   }
    })
  }

  // if (process.env.DEBUG_MODE) // display all notifications for debug mode
  if (error.response.method == "post") {
    type = "error"
  } else {
    if (error.response?.status == 400) type = "error"
    else if (error.response?.status == 404) type = "error"
    else if (error.response?.status == 500) type = "warning"
  }

  if (type) {
    callToastifyFn(message, type)
  }
}

export const callToastifyFn = (message, type) => {
  Toastify({
    text: message,
    duration: 4500,
    newWindow: true,
    node: toastBodyFn(message, type),
    type,
  }).showToast()
}

import { SECLINQAPI } from "@/services/SECLINQAPI";
import BoardingRepo from "@/services/BoardingRepo";
import router from "@/router";

const AuthRepo = SECLINQAPI.get("auth");

export const state = {
    jwtToken: localStorage.getItem("auth.jwtToken"),
    currentUser: JSON.parse(localStorage.getItem("auth.currentUser")),
    boardingPolicies: null,
};

export const mutations = {
    SET_JWT_TOKEN(state, newValue) {
        state.jwtToken = newValue;
        saveStringState("auth.jwtToken", newValue);
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState("auth.currentUser", newValue);
    },

    UPDATE_CURRENT_USER(state, data) {
        const newUser = { ...state.currentUser, ...data };
        state.currentUser = newUser;
        saveState("auth.currentUser", newUser);
    },
    SET_BOARDING_POLICIES(state, newValue) {
        state.boardingPolicies = newValue;
    },
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser;
    },
    // Return boarding policies
    getBoardingPolicies(state) {
        return state.boardingPolicies;
    },
};

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch("validate");
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch("validate");

        return AuthRepo.loginUser({ email: email, password: password }).then((result) => {
            commit("SET_JWT_TOKEN", result.data.value?.access);
            commit("SET_CURRENT_USER", result.data.value?.user);

            return result.data.user;
        });
    },

    mfa({ commit, dispatch, getters }, { token, otp } = {}) {
        return AuthRepo.mfa({ token: token, otp: otp }).then((result) => {
            commit("SET_JWT_TOKEN", result.data.value?.access);
            commit("SET_CURRENT_USER", result.data.value?.user);

            return result.data.value?.user;
        });
    },

    enableMFA({ commit, dispatch, getters }) {
        return AuthRepo.enableMFA().then((result) => {
            return result;
        });
    },

    disableMFA({ commit, dispatch, getters }, data) {
        return AuthRepo.disableMFA(data).then((result) => {
            commit("UPDATE_CURRENT_USER", { mfa_enabled: false });
            return result;
        });
    },

    verifyMFA({ commit, dispatch, getters }, { otp } = {}) {
        return AuthRepo.verifyMFA({ otp: otp }).then((result) => {
            commit("UPDATE_CURRENT_USER", { mfa_enabled: true });
            return result;
        });
    },

    // Logs out the current user.
    logOut({ commit, state }) {
        let currentUser = state.currentUser;
        commit("SET_CURRENT_USER", null);
        localStorage.clear();
        sessionStorage.clear();
        return new Promise((resolve, reject) => {
            AuthRepo.logout(currentUser?.pk)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password, confirmPassword } = {}) {
        if (getters.loggedIn) return dispatch("validate");

        return AuthRepo.registerUser({
            username: username,
            email: email,
            password1: password,
            password2: confirmPassword,
        }).then((response) => {
            const user = response;
            commit("SET_CURRENT_USER", JSON.parse(user));
            return user;
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch("validate");

        return AuthRepo.forgetPassword(email).then((response) => {
            return response.data;
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({ commit, state, getters }) {
        if (!state.currentUser || !state.jwtToken) return Promise.resolve(null);
        return new Promise((resolve, reject) => {
            AuthRepo.tokenVerify({ token: state.jwtToken })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    localStorage.clear();
                    sessionStorage.clear();
                    reject(error);
                });

            if (router.name != "boarding") {
                BoardingRepo.getBoardingPolicies()
                    .then(async (response) => {
                        let boardingPolicies = response.data.value.results || [];
                        await commit("SET_BOARDING_POLICIES", boardingPolicies);
                        resolve(response);
                        if (boardingPolicies?.length) {
                            return router.push({ name: "boarding" });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });

        // return new Promise((resolve, reject) => {
        //   resolve(true)
        // })
    },

    updateUserData({ commit }, data) {
        commit("SET_CURRENT_USER", data);
    },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

function saveStringState(key, state) {
    window.localStorage.setItem(key, state);
}

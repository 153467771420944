import Repo from '../Repo'
import Repo2 from '../Repo2'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "accounts/users"

export default {
  getUsers(query = {}) {
    return Repo2(`${resource}/?${applySearchFilter(query)}`)
  },
  getEmployees(query = {}) {
    return Repo2(`${resource}/?${applySearchFilter(query)}`)
  },
  getUser(id) {
    return Repo2(`${resource}/${id}/`)
  },
  getUserAccounts(id) {
    return Repo2(`${resource}/${id}/`)
  },
  getEmployee(id) {
    return Repo2(`${resource}/${id}/`)
  },
  getAccesses(id) {
    return Repo2(`${resource}/${id}/`)
  },
  createUser(data) {
    return Repo2(`${resource}/`, {
      method: "post",
      data
    })
  },
  updateUser(id, data) {
    return Repo2(`${resource}/${id}/`, {
      method: "patch",
      data
    })
  },
  createEmployee(data) {
    return Repo.post(`${resource}/`, data)
  },
  updateEmployee(id, data) {
    return Repo2(`${resource}/${id}/`, {
      method: "patch",
      data
    })
  },
  delete(id) {
    return Repo.delete(`${resource}/${id}/`)
  },
  changeUserPassword(data) {
    // return Repo.post(`accounts/password/change/`, data)
    return Repo2(`accounts/password/change/`, {
      method: "post",
      data
    })
  },
  options() {
    return Repo2(`${resource}/`, {
      method: "OPTIONS"
    })
  }
}
import Repo2 from "./Repo2";
import { applySearchFilter } from "@/services/utilities/search-filter";

const resource = "/api/v1/policies";

export default {
    getPolicies(query = {}) {
        return Repo2(`${resource}/?${applySearchFilter(query)}`);
    },
    getPolicy(id) {
        return Repo2(`${resource}/${id}`);
    },
    createOrgDetails(data) {
        return Repo2(`${resource}/`, {
            method: "POST",
            data,
        });
    },
    createPolicy(data) {
        return Repo2(`${resource}/`, {
            method: "POST",
            data,
        });
    },
    updatePolicy(id, data) {
        return Repo2(`${resource}/${id}/`, {
            method: "PATCH",
            data,
        });
    },
    delete(id) {
        return Repo2(`${resource}/${id}/`, {
            method: "DELETE",
        });
    },
    logs(id) {
        return Repo2(`${resource}/${id}/logs`);
    },
    options() {
        return Repo2(`${resource}/`, {
            method: "OPTIONS",
        });
    },
};

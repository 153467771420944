import Repo from '../Repo'
import Repo2 from '../Repo2'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/accounts/organizations"

export default {
  getOrganizations(query = {}) {
    return Repo.get(`${resource}/?${applySearchFilter(query)}`)
  },
  getOrganization(id) {
    return Repo2(`${resource}/${id}/`)
  },
  getOrganizationMembers(query = {}) {
    // use organization from user profile
    let currentUser = JSON.parse(localStorage.getItem('auth.currentUser'))
    let organizationID = currentUser?.profile?.organization
    return Repo.get(`${resource}/${organizationID}/members/?${applySearchFilter(query)}`)
  },
  getOrganizationMembers2(query = {}) {
    // use organization from user profile
    let currentUser = JSON.parse(localStorage.getItem('auth.currentUser'))
    let organizationID = currentUser?.profile?.organization
    return Repo2(`${resource}/${organizationID}/members/?${applySearchFilter(query)}`)
  },
  updateOrganization(data) {
    return Repo2(`${resource}/${data?.id}/`, {
      method: "patch",
      data
    })
  },
}

import Repo from '../Repo'
import Repo2 from '../Repo2'

const resource = "accounts"

export default {
  user(id) {
    return Repo.get(`${resource}/users/${id}/`)
  },
  userProfile(user_id) {
    return Repo.get(`${resource}/profiles/${user_id}/`)
  },
  userSettings(user_id) {
    return Repo.get(`${resource}/settings/${user_id}/`)
  },
  loginUser(data) {
    return Repo2(`${resource}/login/`, {
      method: "POST",
      data,
      notificationMessage: "Login Successfully!",
    })
  },
  logout(data=null) {
    return Repo2(`${resource}/logout/`, {
      method: "POST",
      data,
      notificationMessage: "Logout Successfully!",
    })
  },
  mfa(data=null) {
    return Repo2(`${resource}/authenticate-otp/`, {
      method: "POST",
      data,
      notificationMessage: "Logged in Successfully!",
    })
  },
  enableMFA() {
    return Repo2(`${resource}/enable-mfa/`, {
      responseType:"blob",
      hideNotification: true,
    })
  },
  disableMFA(data) {
    return Repo2(`${resource}/disable-mfa/`, {
      method: "PUT",
      data,
      hideNotification: true,
    })
  },
  verifyMFA(data) {
    return Repo2(
      `${resource}/verify-otp/`, 
      {
        method: "POST",
        data,
        hideNotification: true,
      }
    )
  },
  tokenVerify(data) {
    return Repo2(`${resource}/token/verify/`, {
      method: "POST",
      data,
      hideNotification: true,
    })
  },
  tokenRefresh(data) {
    return Repo.post(`${resource}/token/refresh/`, data)
  },
  registerUser(data) {
    return Repo.post(`/registration/`, data)
  },
  passwordChange(data) {
    return Repo.patch(`/password/change/`, data)
  },
  forgetPassword(data) {
    return Repo.patch(`/reset/`, data)
  },
  resetConfirm(data) {
    return Repo.patch(`/reset/confirm/`, data)
  },
}
import Repo from '../Repo'
import Repo2 from '../Repo2'
import { applySearchFilter } from "@/services/utilities/search-filter"

const resource = "/api/v1/vulnerabilities"

export default {
  getAll(query = {}) {
    return Repo2(`${resource}/?${applySearchFilter(query)}`)
  },
  get(id) {
    return Repo.get(`${resource}/${id}`)
  },
  post(data) {
    return Repo.post(`${resource}/`, data)
  },
  patch(id, data) {
    return Repo.patch(`${resource}/${id}/`, data)
  },
  delete(id) {
    return Repo.delete(`${resource}/${id}/`)
  },
}
import axios from "axios"
import { useAxios } from '@vueuse/integrations/useAxios'

import { showResponseMessage, showErrorMessage } from '@/helpers/showResponseMessage'

const baseURL = process.env.VUE_APP_SECLINQ_SERVICES_ENDPOINT
const ApiToken = process.env.VUE_APP_SECLINQ_API_KEY

const useCustomAxios = (url, axiosConfigHeaders = {}, initialData = null) => {

  let headers = {}
  
  const instance = axios.create({
    // setting backend server base URL
    baseURL,
    withCredentials: true,
    // setting user access token to be passed in all requests headers
    headers,
  })

  instance.interceptors.request.use(
    config => {
      let token = localStorage.getItem('auth.jwtToken')

      // If There is token in env then use it (for developers) else use token from session (for users)
      if (token || ApiToken) {
        config.headers['Authorization'] = token ? `Bearer ${ token }` : ApiToken
        headers['Authorization'] = token ? `Bearer ${ token }` : ApiToken
      }

      return config
    }, 

    error => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      showResponseMessage(response, axiosConfigHeaders?.hideNotification, axiosConfigHeaders?.notificationMessage)
      return response
    },
    function (error) {
      showErrorMessage(error, axiosConfigHeaders?.hideNotification, axiosConfigHeaders?.notificationMessage)

      return Promise.reject(error)
    }
  )

  return useAxios(url, axiosConfigHeaders, instance, {
    immediate: true,
    initialData: initialData,
  })
}


export default useCustomAxios
import AuthRepo from './accounts/AuthRepo.js'
import UsersRepo from './accounts/UsersRepo.js'
import ProfileRepo from './accounts/ProfilesRepo.js'
import CompanyRepo from './accounts/OrganizationRepo.js'
import AssetsRepo from './AssetsRepo.js'
import DepartmentGroupsRepo from './DepartmentGroupsRepo.js'
import CloudScanningRepo from './CloudScanningRepo.js'
import CodeReviewRepo from './CodeReviewRepo.js'
import PentestingRepo from './PentestingRepo.js'
import ProjectsRepo from './ProjectsRepo.js'
import VulnerabilitiesRepo from './vulnerability/VulnerabilitiesRepo.js'
import VulnerabilityManagementRepo from './vulnerability/VulnerabilityManagementRepo.js'
import APIKeysRepo from "@/services/accounts/APIKeysRepo"
import RatingRepo from "@/services/vulnerability/RatingRepo"
import StatisticsRepo from "@/services/StatisticsRepo.js"
import PolicyRepo from "@/services/PolicyRepo.js"

const repositories = {
  auth: AuthRepo,
  users: UsersRepo,
  profiles: ProfileRepo,
  api_keys: APIKeysRepo,
  organizations: CompanyRepo,
  assets: AssetsRepo,
  depart_groups: DepartmentGroupsRepo,
  cloud_scanning: CloudScanningRepo,
  code_review: CodeReviewRepo,
  pentesting: PentestingRepo,
  projects: ProjectsRepo,
  vulnerabilities: VulnerabilitiesRepo,
  ratings: RatingRepo,
  vulnerability_management: VulnerabilityManagementRepo,
  statistics: StatisticsRepo,
  policy: PolicyRepo,
}

export const SECLINQAPI = {
  get: name => repositories[name],
}
